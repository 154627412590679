import {Alert} from '@aws-amplify/ui-react';

export function ShowInfo() {

    return (
        <Alert
            style={{margin: "50px"}}
            variation="warning"
            isDismissible={false}
            hasIcon={true}
            heading="Estimado usuario para que su pago sea registrado correctamente debe seleccionar al final
         de la transacción la opción “Regresar al Comercio” ó “Finalizar el proceso”.">
            Si tienes preguntas o inquietudes, comunícate con el comercio escribiendo a
            contabilidad@gimnasiomariecurie.edu.co o llamando al +57 313 6771251.
        </Alert>
    );
}