import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {ExportToCsv} from 'export-to-csv'

//MRT Imports
import MaterialReactTable from 'material-react-table';
//Material-UI Imports
import {Box, Button, ListItemIcon, MenuItem} from '@mui/material';
import {MRT_Localization_ES} from 'material-react-table/locales/es';
import {Edit, FileDownload} from '@mui/icons-material';
//Date Picker Imports
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {es} from 'date-fns/locale';
import {CreateModal} from "./CreateModal";


const AdminTable = ({user}) => {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [tableData, setTableData] = useState([]);
    const [lastItem, setLastItem] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const [payerToEdit, setPayerToEdit] = useState(null);
    const [fileReady, setFileReady] = useState(false);
    const tableDataRef = useRef(tableData);

    const getCommonEditTextFieldProps = useCallback((cell) => {
        return {
            error: !!validationErrors[cell.id], helperText: validationErrors[cell.id], onBlur: (event) => {
                const isValid = cell.column.id === 'email' ? validateEmail(event.target.value) : cell.column.id === 'age' ? validateAge(+event.target.value) : validateRequired(event.target.value);
                if (!isValid) {
                    //set validation error for cell if invalid
                    setValidationErrors({
                        ...validationErrors, [cell.id]: `${cell.column.columnDef.header} is required`,
                    });
                } else {
                    //remove validation error for cell if valid
                    delete validationErrors[cell.id];
                    setValidationErrors({
                        ...validationErrors,
                    });
                }
            },
        };
    }, [validationErrors],);

    function stringToJson(string, len) {
        let json = []
        for (let i = 0; i < len; i++) {
            json.push(JSON.parse(string[i]))
        }
        return json;
    }

    const handlePayers = (user, fromDelete, deleteLength) => {
        setIsLoading(true)
        fetch(process.env.REACT_APP_API_URL + "payExtranet", {
            method: 'POST', headers: {
                'Content-type': 'application/json', 'Accept': 'application/json'
            }, // Strigify the payload into JSON:
            body: JSON.stringify({
                'codigo': user,
                'userId': Number(localStorage.getItem('userId')),
                'lastItem': lastItem,
            })
        }).then(res => {
            if (res.status === 200) {
                return res.json()
            }
            if (res.status === 400) {
                return res.json()
            }
        }).then(jsonResponse => {
            // Agregar set para lastItem
            // setTableData([...stringToJson(jsonResponse['message'], jsonResponse['message']['length'])])
            setFileReady(true)
            if (fromDelete) alert(deleteLength > 1 ? "Los elementos fueron eliminados con éxito" : "El elemento fue eliminado con éxito")
        }).catch((err) => {
            alert("Ocurrió un problema al cargar la lista, comuníquese con un administrador")
            setIsLoading(false)
            console.error(err)
        });
    };

    useEffect(() => {
        handlePayers(user);
    }, [])

    useEffect(() => {
        if (fileReady){
            setIsLoading(true)
            fetch('/data/payer.json') // Relative path to the file
                .then((response) => response.json())
                .then((data) => {
                    setTableData([...stringToJson(data, data.length)])
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.error('Error fetching file:', error)
                    setIsLoading(false)
                });
        }
    }, [fileReady])

    useEffect(() => {
        tableDataRef.current = tableData;
    }, [tableData]);

    const columns = useMemo(() => [{
        accessorKey: 'realId',
        header: 'ID Pago',
        filterFn: 'contains',
        size: 150,
        id: 'id',
        type: "text",
        enableClickToCopy: true,
        muiTableBodyCellProps: {
            align: 'center',
        },
        muiTableHeadCellProps: {
            align: 'center',
        },
    }, {
        accessorKey: 'name', header: 'Nombre', size: 150, id: 'Nota', filterFn: 'contains', enableClickToCopy: true,
    }, {
        id: 'Tercero_Externo',
        accessorKey: 'identification',
        filterFn: 'contains',
        enableClickToCopy: true,
        header: 'Tercero Externo',
        size: 25,
        type: 'number',
        muiTableBodyCellProps: {
            align: 'center',
        }
    }, {
        id: 'Tercero_Interno',
        accessorKey: 'secondId',
        enableClickToCopy: true,
        filterFn: 'contains',
        header: 'Tercero Interno',
        size: 25,
        type: 'number',
        muiTableBodyCellProps: {
            align: 'center',
        }
    }, {
        id: 'Valor_Unitario',
        accessorFn: (row) => row.amount / 100,
        accessorKey: 'amount',
        filterVariant: 'range',
        header: 'Valor a pagar',
        enableClickToCopy: true,
        muiTableHeadCellProps: {
            align: 'center',
        },
        muiTableBodyCellProps: {
            align: 'center',
        },
        type: 'number',
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
            ...getCommonEditTextFieldProps(cell), type: 'number',
        }),
        size: 50, //custom conditional format and styling
        Cell: ({renderedCellValue, row}) => (<Box>
            {renderedCellValue?.toLocaleString?.('es-CO', {
                style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0,
            })}
        </Box>),
    }, {
        accessorKey: 'discount',
        header: 'Descuento (%)',
        size: 50,
        type: 'number',
        enableClickToCopy: true,
        id: 'Descuento',
        filterVariant: 'range',
        muiTableHeadCellProps: {
            align: 'center',
        },
        muiTableBodyCellProps: {
            align: 'center',
        },
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
            ...getCommonEditTextFieldProps(cell), type: 'number',
        }),
    }, {
        id: 'discount_amount',
        accessorFn: (row) => row.discount_amount ? row.discount_amount / 100 : 0,
        accessorKey: 'discount_amount',
        filterVariant: 'range',
        header: 'Valor del descuento',
        enableClickToCopy: true,
        muiTableHeadCellProps: {
            align: 'center',
        },
        muiTableBodyCellProps: {
            align: 'center',
        },
        type: 'number',
        size: 50, //custom conditional format and styling
        Cell: ({renderedCellValue, row}) => (<Box>
            {renderedCellValue?.toLocaleString?.('es-CO', {
                style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0,
            })}
        </Box>),
    }, {
        accessorKey: 'paydescription',
        header: 'Concepto',
        size: 200,
        enableClickToCopy: true,
        id: 'Producto',
        filterFn: 'contains',
        type: "text",
    }, {
        accessorKey: 'personalized',
        accessorFn: (row) => row.personalized !== null ? row.personalized : '',
        header: 'Nota personalizada',
        size: 200,
        enableClickToCopy: true,
        id: 'Personalizado_1',
        filterFn: 'contains',
        type: "text",
    }, {
        accessorFn: (row) => row.payState !== null ? row.payState : "POR PAGAR",
        accessorKey: 'payState',
        header: 'Estado',
        size: 200,
        name: 'estado',
        filterVariant: 'select',
        filterSelectOptions: ['PAGADO', 'POR PAGAR'],
        id: "Estado_Pago",
        type: "multiple",
        enableClickToCopy: true,
        muiTableBodyCellProps: {
            align: 'center',
        },
    }, {
        accessorFn: (row) => {
            if (row.date !== undefined) {
                let parts = row.date.split("/");
                return new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10));
            } else return new Date()
        }, //convert to Date for sorting and filtering
        accessorKey: "date",
        id: 'Vencimiento',
        size: 50,
        header: 'Fecha de pago',
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        name: 'fecha',
        enableClickToCopy: true,
        muiTableBodyCellProps: {
            align: 'center',
        },
        muiTableBodyCellEditTextFieldProps: ({cell}) => ({
            ...getCommonEditTextFieldProps(cell), type: 'date',
        }),
        Cell: ({cell}) => cell.getValue()?.toLocaleDateString('es'), //render Date as a string
        Filter: ({column}) => (<LocalizationProvider
            dateAdapter={AdapterDateFns} adapterLocale={es}>
            <DatePicker
                onChange={(newValue) => {
                    column.setFilterValue(newValue);
                }}
                slotProps={{
                    textField: {
                        helperText: 'Modo de filtro: Antes de', sx: {minWidth: '120px'}, variant: 'standard',
                    },
                }}
                value={column.getFilterValue()}
            />
        </LocalizationProvider>),
    },], [getCommonEditTextFieldProps],);

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.id),
    };

    const csvExporter = new ExportToCsv(csvOptions);
    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => {
            return {
                id: row.original.realId,
                Nota: row.original.name,
                Tercero_Externo: row.original.identification,
                Tercero_Interno: row.original.secondId,
                Valor_Unitario: row.original.amount / 100,
                'Nota.1': row.original.paydescription,
                Estado_Pago: row.original.payState,
                Vencimiento: row.original.date
            };
        }));
    };

    const handleDelete = useCallback((deleteItems) => {
        setIsLoading(true)
        fetch(process.env.REACT_APP_API_URL + "operatePayer", {
            method: 'DELETE', headers: {
                'Content-type': 'application/json', 'Accept': 'application/json'
            }, // Strigify the payload into JSON:
            body: JSON.stringify(deleteItems)
        }).then(res => {
            if (res.status === 204) {
                handlePayers(user, true, deleteItems.length)
            } else {
                alert("El elemento no pudo ser eliminado")
            }
        })
        //send api delete request here, then refetch or update local table data for re-render
    }, [tableData]);

    const handleCreateNewRow = (values) => {
        console.log(JSON.stringify(values))
        fetch(process.env.REACT_APP_API_URL + "operatePayer", {
            method: payerToEdit ? 'PUT' : 'POST', headers: {
                'Content-type': 'application/json', 'Accept': 'application/json'
            }, // Strigify the payload into JSON:
            body: JSON.stringify(values)
        }).then(res => {
            let newData = []
            if (res.status >= 200 && res.status < 300) {
                newData = [...tableDataRef.current];
                let date = new Date(values.Vencimiento)
                if (payerToEdit) newData.splice(payerToEdit.index, 1)
                newData.unshift({
                    realId: values.id,
                    identification: values.Tercero_Externo,
                    amount: values.Valor_Unitario * 100,
                    secondId: values.Tercero_Interno,
                    discount: values.Descuento,
                    discount_amount: (values.Valor_Unitario * 100) * (values.Descuento / 100),
                    date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
                    name: values.Nota,
                    personalized: values.Personalizado_1,
                    paydescription: `${values.Producto.toUpperCase()} ${date.toLocaleDateString("es-CO", {month: "long"}).toUpperCase()} ${date.toLocaleDateString("es-CO", {year: "numeric"})} ${values.Nota.toUpperCase()}`,
                    payState: values.Estado_Pago,
                    payReference: `${values.Tercero_Externo}-${values.Tercero_Interno}`,
                    totalAmount: values.Valor_Unitario * 100
                });
                setTableData([...newData]);
                alert(payerToEdit ? "El elemento fue modificado con éxito" : "El elemento fue creado con éxito")
                setCreateModalOpen(false)
            } else {
                alert(payerToEdit ? "El elemento no pudo ser modificado" : "El elemento no pudo ser creado")
            }
        })

    };

    const handleSaveRowEdits = async ({exitEditingMode, row, values}) => {
        console.log(`Values ${JSON.stringify(values)}`)
        if (!Object.keys(validationErrors).length) {
            tableData[row.index] = values;
            //send/receive api updates here, then refetch or update local table data for re-render
            setTableData([...tableData]);
            exitEditingMode(); //required to exit editing mode and close modal
        }
    };

    const validateRequired = (value) => !!value.length;
    const validateEmail = (email) => !!email.length && email
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,);
    const validateAge = (age) => age >= 18 && age <= 50;

    return (<>
        <MaterialReactTable
            columns={columns}
            data={tableData}
            state={{isLoading}}
            enableColumnOrdering
            enableColumnFilterModes
            enableGrouping
            enablePinning
            enableRowActions
            enableRowSelection
            onEditingRowSave={handleSaveRowEdits}
            initialState={{showColumnFilters: true, density: 'compact', pagination: {pageSize: 15}}}
            positionToolbarAlertBanner="bottom"
            localization={MRT_Localization_ES}
            muiTablePaginationProps={{
                rowsPerPageOptions: [15, 25, 30, 50, 100, 300],
            }}
            renderRowActionMenuItems={({closeMenu, row, table}) => [<MenuItem
                key={1}
                onClick={() => {
                    setPayerToEdit(row)
                    setCreateModalOpen(true)
                    closeMenu();
                }}
                sx={{m: 0}}>
                <ListItemIcon>
                    <Edit/>
                </ListItemIcon>
                Modificar
            </MenuItem>,]}
            renderTopToolbarCustomActions={({table}) => {
                const handleMultipleDelete = () => {
                    if (window.confirm(`¿Está seguro de eliminar ${table.getSelectedRowModel().flatRows.length} elementos?`)) {
                        handleDelete(table.getSelectedRowModel().flatRows)
                        table.resetRowSelection()
                    }
                };
                return (<div style={{display: 'flex', gap: '0.5rem'}}>
                    <Button
                        color="success"
                        onClick={() => {
                            setPayerToEdit(null)
                            setCreateModalOpen(true)
                        }}
                        variant="contained"
                    >
                        Crear pago
                    </Button>
                    <Button
                        disabled={table.getPrePaginationRowModel().rows.length === 0 || isLoading}
                        //export all rows, including from the next page, (still respects filtering and sorting)
                        onClick={() => handleExportRows(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected() ? table.getSelectedRowModel().rows : table.getPrePaginationRowModel().rows)}
                        startIcon={<FileDownload/>}
                        variant="contained"
                    >
                        {table.getIsSomeRowsSelected() || table.getIsAllRowsSelected() ? "Exportar datos seleccionados" : "Exportar datos"}
                    </Button>
                </div>);
            }}
        />
        <CreateModal
            columns={columns}
            open={createModalOpen}
            onClose={() => {
                setPayerToEdit(null)
                setCreateModalOpen(false)
            }}
            onSubmit={handleCreateNewRow}
            defaultValues={payerToEdit}
        />
    </>);
};

export default AdminTable;