import React from 'react'
import {Auth} from 'aws-amplify';
import {Button} from '@aws-amplify/ui-react';
import {Icon} from '@aws-amplify/ui-react';
import "./SignOut.css";

export function SignOut() {

    const handleSignout = async () => {
        try {
            await Auth.signOut();
            localStorage.removeItem('Codigo')
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    return (
        <div>
            <Button size='medium' variation='primary' onClick={handleSignout} className="signOutButton"><Icon
                ariaLabel="Favorite"
                viewBox={{width: 24, height: 24}}>
                <path
                    d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"/>
                <path d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22"/>
            </Icon>
                Cerrar Sesión
            </Button>
        </div>

    )
}
