import React, {useEffect, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    FormControl
} from '@mui/material';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {es} from "date-fns/locale";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";

export const CreateModal = ({open, columns, onClose, onSubmit, defaultValues}) => {
    const [values, setValues] = useState(() => columns.reduce((acc, column) => {
        acc[column.id ?? ''] = '';
        return acc;
    }, {}),);

    const handleSubmit = () => {
        //put your validation logic here
        onSubmit(values);
    };

    useEffect(() => {
        if (defaultValues) {
            let parts = defaultValues.original?.date?.split("/");
            let defaultDate = parts ? new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10)) : new Date()
            setValues({
                id: defaultValues.original.realId,
                Tercero_Externo: defaultValues.original.identification,
                Valor_Unitario: Number(defaultValues.original.amount / 100),
                Tercero_Interno: defaultValues.original.secondId,
                Descuento: Number(defaultValues.original.discount),
                Vencimiento: defaultDate,
                Personalizado_1: defaultValues.original.personalized,
                Nota: defaultValues.original.name,
                Producto: defaultValues.original.paydescription?.split(' ')[0],
                Estado_Pago: defaultValues.original.payState ? defaultValues.original.payState : "POR PAGAR",
            })
            defaultValues.original.date = defaultDate;
            console.log(JSON.stringify(defaultValues))
        }
    }, [defaultValues])
    useEffect(() => {
        console.log(values)
    }, [values])

    return (<Dialog open={open}>
        <DialogTitle textAlign="center">{`${defaultValues ? "Modificar" : "Crear"} pago`}</DialogTitle>
        <DialogContent style={{paddingTop: "20px"}}>
            <form onSubmit={(e) => e.preventDefault()}>
                <Stack
                    sx={{
                        marginBottom: "50px",
                        width: '100%',
                        minWidth: {xs: '300px', sm: '360px', md: '400px'},
                        gap: '1.5rem',
                    }}
                >
                    {columns.map((column, index) => {
                        if (column.accessorKey === "date") {
                            return <LocalizationProvider
                                key={index}
                                dateAdapter={AdapterDateFns} adapterLocale={es}>
                                <DatePicker
                                    name={column.id}
                                    label={column.header}
                                    onChange={(newValue) => setValues({...values, [column.id]: newValue})}
                                    value={defaultValues ? values.Vencimiento : ''}
                                    renderInput={(params) => (<TextField
                                        {...params}
                                        sx={{minWidth: '120px'}}
                                        variant="standard"
                                    />)}
                                />
                            </LocalizationProvider>
                        } else if (column.accessorKey === 'amount') {
                            return <TextField
                                key={index}
                                label={column.header}
                                name={column.id}
                                type={column.type}
                                defaultValue={defaultValues ? defaultValues.original[column.accessorKey] / 100 : ''}
                                onChange={(e) => setValues({...values, [e.target.name]: e.target.value})}
                            />

                        } else if (column.accessorKey === 'discount_amount') {
                            return <TextField
                                key={index}
                                label={column.header}
                                name={column.id}
                                contentEditable={false}
                                type={column.type}
                                value={values.Valor_Unitario * (values.Descuento / 100)}
                            />
                        } else if (column.type !== 'multiple') return <TextField
                            key={index}
                            label={column.header}
                            name={column.id}
                            type={column.type}
                            defaultValue={defaultValues ? defaultValues.original[column.accessorKey] : ''}
                            onChange={(e) => setValues({...values, [e.target.name]: e.target.value})}
                        />
                    })}
                    <FormControl fullWidth>
                        <InputLabel id="label-prducto">Producto</InputLabel>
                        <Select
                            labelId="label-prducto"
                            placeholder={"Producto"}
                            id="producto"
                            name={'Producto'}
                            label="Producto"
                            defaultValue={defaultValues ? defaultValues.original.paydescription?.split(' ')[0].toUpperCase() : ''}
                            onChange={(e) => setValues({...values, [e.target.name]: e.target.value})}
                        >
                            <MenuItem value={"PENSION"}>Pensión</MenuItem>
                            <MenuItem value={"RESTAURANTE"}>Restaurante</MenuItem>
                            <MenuItem value={"OTRO"}>Otro</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="label-estado">Estado</InputLabel>
                        <Select
                            labelId="label-estado"
                            placeholder={"Estado"}
                            id="estado"
                            name={'Estado_Pago'}
                            label="Estado"
                            defaultValue={defaultValues ? defaultValues.original.payState ? defaultValues.original.payState.toUpperCase() : "POR PAGAR" : ''}
                            onChange={(e) => setValues({...values, [e.target.name]: e.target.value})}
                        >
                            <MenuItem value={"POR PAGAR"}>Por pagar</MenuItem>
                            <MenuItem value={"PAGADO"}>Pagado</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
            </form>
        </DialogContent>
        <DialogActions sx={{p: '1.25rem'}}>
            <Button onClick={() => {
                setValues({})
                onClose()
            }}>
                Cancelar
            </Button>
            <Button color="success"
                    onClick={() => {
                        handleSubmit()
                    }}
                    variant="contained">
                {`${defaultValues ? "Modificar" : "Crear"}`}
            </Button>
        </DialogActions>
    </Dialog>);
};