import { Alert } from '@aws-amplify/ui-react';

export function ShowAlert({isAlert}) {
    if(isAlert === 404)
  return( 
    <div>
        <Alert 
        variation="info"
        isDismissible={false}
        hasIcon={true}
        heading="Estimado usuario: ">No tiene cuentas por pagar en este momento.
        </Alert>
    </div>
);
}