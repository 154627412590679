import React, {useEffect, useState, useRef} from 'react'
import Wompi from "../../../utils/image/descarga.png";
import {ShowAlert} from "../alerts/ShowAlert"
import {ShowInfo} from '../alerts/ShowInfo';
import {Helmet} from "react-helmet";
import {Button} from '@aws-amplify/ui-react';
import {Heading} from '@aws-amplify/ui-react';
import {SignOut} from "../signOut/SignOut";
import "./ShowPayers.css";
import {StudentTable} from "./StudentTable";
import AdminTable from "./AdminTable";

export function ShowPayers({user, isAdmin}) {

    const WidgetCheckout = useRef();
    const [payer, setPayer] = useState([])
    const [infoAlert, setAlert] = useState([])

    function widgetCheckout(codigo, monto, referencia) {
        var checkout = new window.WidgetCheckout({
            currency: 'COP',
            amountInCents: monto,
            reference: referencia,
            publicKey: process.env.REACT_APP_PUBLIC_KEY_WOMPI,
            redirectUrl: process.env.REACT_APP_REDIRECT_URL_WOMPI
        })
        return checkout
    }


    function handleReload() {
        window.location.reload(true)
    }

    function openWidget(codigo, monto, referencia) {
        var checkout = widgetCheckout(codigo, monto, referencia)
        checkout.open(function (result) {
            var transaction = result.transaction
            getReponseEvent(transaction)
            setTimeout(handleReload, 3000);
        })
    }

    const handlePayers = (user) => {
        localStorage.setItem('Codigo', user);
        fetch(process.env.REACT_APP_API_URL + "payExtranet", {
            method: 'POST', headers: {
                'Content-type': 'application/json', 'Accept': 'application/json'
            }, // Strigify the payload into JSON:
            body: JSON.stringify({'codigo': user, 'userId': Number(localStorage.getItem('userId'))})
        }).then(res => {
            if (res.status === 200) {
                return res.json()
            }
            if (res.status === 400) {
                return res.json()
            }
            if (res.status === 404) {
                setAlert(res.status)
            }
        }).then(jsonResponse => {
            setPayer(stringToJson(jsonResponse['message'], jsonResponse['message']['length']))
        }).catch((err) => {
            alert("Ocurrió un problema al cargar la lista, comuníquese con un administrador")
            console.error(err)
        });
    };

    function getReponseEvent(transaction) {
        // Get the reciever endpoint from Python using fetch:
        fetch(process.env.REACT_APP_API_URL + "payResponse", {
            method: 'POST', headers: {
                'Content-type': 'application/json', 'Accept': 'application/json'
            }, // Strigify the payload into JSON:
            body: JSON.stringify(transaction)
        }).then(res => {
            if (res.ok) {
                return res.json()
            } else {
                alert("something is wrong")
            }
        }).then(jsonResponse => {
            // Log the response data in the console
            /*if (jsonResponse[0] == "OK"){
                openWidget(cedula, monto, jsonResponse[1])
            }
            if (jsonResponse[0] == "BAD"){
                openModal()
            }*/
            console.log(JSON.stringify(jsonResponse))
        }).catch((err) => console.error(err));
    }

    function getTransactionData() {
        const queryString = window.location.search;
        if (queryString !== "") {
            const urlParams = new URLSearchParams(queryString);
            // Get the reciever endpoint from Python using fetch:
            fetch(process.env.REACT_APP_API_URL + "transactionData", {
                method: 'POST', headers: {
                    'Content-type': 'application/json', 'Accept': 'application/json'
                }, // Strigify the payload into JSON:
                body: JSON.stringify({'id': urlParams.get('id')})
            }).then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    alert("something is wrong")
                }
            }).then(jsonResponse => {
                // Log the response data in the console
                /*if (jsonResponse[0] == "OK"){
                    openWidget(cedula, monto, jsonResponse[1])
                }
                if (jsonResponse[0] == "BAD"){
                    openModal()
                }*/
                let res = JSON.stringify(jsonResponse)
                res = JSON.parse(res)
                console.log(res)
            }).catch((err) => console.error(err));
        }
    }

    function stringToJson(string, len) {
        var json = []
        for (let i = 0; i < len; i++) {
            json.push(JSON.parse(string[i]))
        }
        return json;
    }

    useEffect(() => {
        getTransactionData();
        if (!isAdmin) {
            handlePayers(user);
            const scriptTag = document.createElement("script");
            scriptTag.src = "https://checkout.wompi.co/widget.js";
            document.head.appendChild(scriptTag);
            scriptTag.onload = () => {
                WidgetCheckout.current = new window.WidgetCheckout({
                    currency: 'COP',
                    amountInCents: 1,
                    reference: 'new',
                    publicKey: process.env.REACT_APP_PUBLIC_KEY_WOMPI
                });
            };
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            {isAdmin ? <AdminTable user={user}/> : <>
                <ShowAlert isAlert={infoAlert}/>
                <br/>
                <ShowInfo/>
                <br/>
                <Helmet>
                    <script type="text/javascript" src="https://checkout.wompi.co/widget.js"/>
                </Helmet>
                <StudentTable payer={payer}/>
                <div>
                    <Button size='medium' variation='primary' onClick={() => {
                        openWidget(payer.at(0).identification, payer.at(0).totalAmount, payer.at(0).payReference)
                    }} className="wompi-button">PAGAR</Button>
                    <div align="center">
                        <Heading level={4} className="wompi-text">Sistema de pago en línea</Heading>
                        <img src={Wompi} className="wompi-image" alt="Wompi logo"/>
                    </div>
                </div>
            </>}
            <SignOut/>
        </div>)
}
