import {Alert} from '@aws-amplify/ui-react';
import {Auth} from 'aws-amplify';
import React, {useEffect} from 'react'

export function DeleteNoUser() {

    async function deleteUser() {
        try {
            const result = await Auth.deleteUser();
            console.log(result);
        } catch (error) {
            console.log('Error deleting user', error);
        }
    }

    useEffect(() => {
        setTimeout(deleteUser, 7000);

    }, [])

    return (
        <div>
            <Alert
                variation="error"
                isDismissible={false}
                hasIcon={true}
                heading="Estimado usuario: ">Verifique que el Email ingresado sea institucional e inténtelo nuevamente.
                Será redirigido a la pestaña de Inicio de sesión.
            </Alert>
        </div>
    );
}