import {Table, TableBody, TableCell, TableHead, TableRow} from "@aws-amplify/ui-react";
import React from "react";

export function StudentTable({payer}) {

    function currencyToCOP(number) {
        return number.toLocaleString('us-US', {style: 'currency', currency: 'COP'});
    }

    return (
        <Table size="small" variation='striped' highlightOnHover={true} className="custom-table">
            <TableHead>
                <TableRow>
                    <TableCell as="th">Fecha Pago Oportuno</TableCell>
                    <TableCell as="th">Valor a Pagar</TableCell>
                    <TableCell as="th">Nombre Alumno</TableCell>
                    <TableCell as="th">Descripción Pago</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {payer.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell as="td">{item.date}</TableCell>
                        <TableCell as="td">{currencyToCOP(item.amount / 100)}</TableCell>
                        <TableCell as="td">{item.name}</TableCell>
                        <TableCell as="td">{item.paydescription}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}