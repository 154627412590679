import React, {useEffect, useState} from 'react'
import {DeleteNoUser} from "../user/DeleteNoUser";
import {ShowPayers} from "../payers/ShowPayers";

export function GetEmail({user, email}) {

    const [validateEmail, setValidateEmail] = useState('')
    const [isAdmin, setIsAdmin] = useState(false)

    const handleEmail = (user, email) => {
        fetch(process.env.REACT_APP_API_URL + "validateEmail",
            {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/json'
                },
                // Strigify the payload into JSON:
                body: JSON.stringify({'codigo': user, 'correo': email})
            }).then(res => {
            if (res.status === 200) {
                return res.json()
            }
            if (res.status === 400) {
                return res.json()
            }
            if (res.status === 404) {
                return res.json()
            }
        }).then(jsonResponse => {
                console.log(jsonResponse['userId'])
                setValidateEmail(jsonResponse['message'])
                setIsAdmin(jsonResponse['mainUser'])
                localStorage.setItem('userId', jsonResponse['userId'])
            }
        ).catch((err) => console.error(err));
    };

    useEffect(() => {
        handleEmail(user, email);
        // eslint-disable-next-line
    }, [])

    if (validateEmail === 'OK')
        return (
            <div>
                <ShowPayers user={user} isAdmin={isAdmin}/>
            </div>
        );
    else if (validateEmail === 'BAD')
        return (
            <div>
                <DeleteNoUser/>
            </div>
        );
}