import {I18n} from 'aws-amplify';
import {translations} from '@aws-amplify/ui-react';
import {Amplify} from 'aws-amplify'
import awsconfig from './aws-exports';
import {Authenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import Logo from "./utils/image/logo.png";
import {useAuthenticator} from '@aws-amplify/ui-react';
//components
import {GetEmail} from './components/extranet/signUp/GetEmail';
import "./App.css";

Amplify.configure(awsconfig)

I18n.putVocabularies(translations);
I18n.setLanguage('es');

I18n.putVocabularies({
    es: {
        'Sign In': 'Inicio de sesión',
        'Reset Password': 'Recupere su contraseña',
        Username: 'Código de Estudiante',
        Code: 'Código de verificación'
    },
});

const formFields = {
    signIn: {
        username: {
            placeholder: 'Código de Estudiante',
            isRequired: true,
            labelHidden: true
        },
        password: {
            placeholder: 'Contraseña',
            isRequired: true,
            labelHidden: true
        },
    },
    signUp: {
        username: {
            placeholder: 'Código de Estudiante',
            isRequired: true,
            labelHidden: true
        },
        password: {
            placeholder: 'Contraseña',
            isRequired: true,
            labelHidden: true
        },
        confirm_password: {
            placeholder: 'Confirmar Contraseña',
            isRequired: true,
            labelHidden: true
        },
        email: {
            placeholder: 'Email institucional',
            isRequired: true,
            labelHidden: true
        },
    },
    confirmResetPassword: {
        confirmation_code: {
            placeholder: 'Código de verificación',
            label: 'Código de verificación (revise su correo institucional)',
            isRequired: true,
        },
    },
    resetPassword: {
        username: {
            placeholder: 'Código de Estudiante',
        },
    },
}


function App() {
    // eslint-disable-next-line
    const {route} = useAuthenticator(context => [context.route]);

    return (
        <div className='App-header'>
            <div className='App-logo'>
                <img src={Logo} alt=''/>
            </div>
            <Authenticator formFields={formFields}>
                {({user}) => (
                    <div className='App'>
                        <h2>Cuentas por pagar</h2>
                        <GetEmail user={user.username} email={user.attributes.email}/>
                    </div>
                )}
            </Authenticator>
        </div>
    );
}

export default App;